$(function(){
  // スクロールでロゴ非表示
  function displayLogo(){
    var scrollTop = $(window).scrollTop();
    if (scrollTop > 300) {
      $(".sitename").fadeOut(400);
    } else {
      $(".sitename").fadeIn(200).css('paddingBottom',scrollTop+'px');
    }
  }
  $(window).on('load scroll', displayLogo);
  //toTopの表示・非表示
  function displayToTop(){
    var scrollTop = $(window).scrollTop();
    if (scrollTop < 300) {
      $(".to-page-top").fadeOut();
    } else {
      $(".to-page-top").fadeIn();
      $(".to-page-top a").smoothScroll({offset: 0});
    }
  }
  $(window).on("load scroll", displayToTop);
  // navbar toggle
  $(".navbar-toggler").click(function(){
    $(".menu1").toggleClass("menuclick1");
    $(".menu2").toggleClass("menuclick2");
    $(".menu3").toggleClass("menuclick3");
  });
  if ($('body').hasClass('home')) {
    // feature
    $('#feature .slide').slick({
      dots: false,
      arrows: false,
      infinite: true,
      speed: 2000,
      autoplay: true,
      autoplaySpeed: 4000,
      adaptiveHeight: true,
      fade: true
    });
    // instagram
    $('#instagram').imagesLoaded(function(){
      $(".instagram").text("loading...");
      $.ajax({
        url: '/insta.php',
        dataType: 'json',
        error: function(jqXHR, textStatus, errorThrown) {
          $("#instagram .instagram").text(textStatus);
        },
        success: function(data) {
          var insert = '';
          for (var i = 0; i < data.length; i++) {
            // insert += '<div class="col-4 col-sm-3 col-md-2">';
              // 画像とリンク先
              // insert += '<a href="' + data[i]['link'] + '" target="_blank">';
              // insert += '<img src="' + data[i]['images']['low_resolution']['url'] + '" class="instagram-image img-fluid" />';
              // insert += '</a>';
            // insert += '</div>';

            insert += '<a href="' + data[i]['link'] + '" target="_blank">';
            insert += '<div class="insta-img" style="background:url(' + data[i]['images']['low_resolution']['url'] + ')no-repeat center center;background-size:cover;width: 100%;padding-bottom: 100%;"></div>';
            insert += '</a>';
          };
          $('#instagram .insta-photos').append(insert);
          $("#instagram .instagram").text("");
        }
      });
    });
  }
  if ($('body').hasClass('lesson')) {
    $('#ac').on("click", function() {
      $(this).next().slideToggle();
    });
  }
});
